nav {
    height: 80px;
    color: black;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
  }

  nav.fixed {
    position: fixed;
    background-color: white;
    top: 0;
    height: 90px;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .active {
    background-color: pink;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to right, #FF69B4, #FF1493);
    transition: background-color 0.3s ease;
  }
  

  label.logo {
    /* border: 2px solid red; */
    /* color: white; */
    /* font-size: 35px; */
    /* align-items: center; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    line-height: 80px;
    /* padding: 0 100px; */
    font-weight: bold;
  }
  
  nav ul {
    float: right;
    margin-right: 35px;
  }
  
  nav ul li {
    display: inline-block;
    line-height: 80px;
    margin: 0 15px;
  }
  
  nav ul li a {
    color: black;
    font-size: 17px;
    text-transform: uppercase;
  }
  
  .checkbtn {
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 20px;
    cursor: pointer;
    display: none;
    position: relative;
    z-index: 2000;
  }
  
  .checkbtntt {
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 25px;
    cursor: pointer;
  }
  
  #check {
    display: none;
  }
  
  @media (max-width: 952px) {
    label.logo {
      font-size: 30px;
      padding-left: 50px;
    }
    
    nav ul li a {
      font-size: 16px;
    }
  }
  @media (max-width: 600px) {
    label > img {
        margin-top: 10px;
        width: 60px;
        height: 60px;
    }
  }

  @media (max-width: 1100px) {
    .checkbtn {
      display: block;
    }
  
    nav ul {
      position: fixed;
      width: 100%;
      height: 100vh;
      background-color: white;
      top: 90px; /* Adjust the value as needed */
      left: -100%;
      text-align: center;
      transition: all 0.5s;
    }
  
    nav ul li {
      display: block;
      margin: 50px 0;
      line-height: 30px;
    }
  
    nav ul li a {
      font-size: 20px;
      color: black;
    }
  
    #check:checked ~ ul {
      left: 0;
    }
  }
  