.container_login {
  width: 350px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #fc82c4;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

#email,
#password {
  height: 30px;
  background-color: #d6147c;
  width: 90%;
  color: white;
  padding: 10px;
  border-color: #d6147c;
}
input::placeholder {
  color: white;
}

h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form-group button {
  width: 100%;
  padding: 8px;
  background-color: #d6147c;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: 60%;
  /* padding: 20px; */
  margin-left: 50px;
  /* padding-left: 88px; */
  align-items: center;
  justify-content: center;
}
.input-root {
    border: none !important;
  }
  
  .input-field {
    border-bottom: none !important;
  }
  
.form-group button:hover {
  background-color: white;

  color: #d6147c;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.forgot-password {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}

.forgot-password a {
  color: white;
  text-decoration: none;
}
.sign-up {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .sign-up a {
    color: white;
    text-decoration: none;
  }
  