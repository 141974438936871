.container_signup {
    width: 350px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #FC82C4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  #email, #password, #confirmPassword, #username {
    height: 30px;
    background-color: #D6147C;
    border-color: #D6147C;
    width: 90%;
    padding: 10px;
  }
  input::placeholder {
color: white;
}

  h2 {
    text-align: center;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .form-group input[type="email"],
  .form-group input[type="password"],
  .form-group input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .form-group button {
    width: 100%;
    padding: 8px;
    background-color: #D6147C;
    border: none;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    width: 60%;
    padding: 15px;
    /* margin-left: 50px; */
    /* padding-left: 88px; */
    align-items: center;
    justify-content: center;
  }

  .form-group button:hover {
    background-color: white;
    color: #D6147C;
  }

  .error-message {
    color: red;
    margin-top: 5px;
  }

  .already-account {
    text-align: center;
    margin-top: 20px;
  }

  .already-account a {
    color: #d61b8e;
    text-decoration: none;
  }