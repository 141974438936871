.card {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .card-body{
    width: 300px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    border: 2px solid blue;
    margin-top: 20px;
    position: relative; /* Added position relative */
  }
  /* .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
    margin-top: -10px;
  } */
  
  .card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px; /* Added margin-top to align with line */
  }
  
  .card-description {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .card-price {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .card-availability {
    font-size: 14px;
    margin-bottom: 5px;
    color: gray;
  }
  
  .card-more-items {
    font-size: 14px;
    color: blue;
    cursor: pointer;
  }
  
  .card-coming-soon {
    font-size: 14px;
    color: red;
    font-style: italic;
  }
  